import React, { useState } from 'react';
import { navigate } from 'gatsby';
import addToMailchimp from 'gatsby-plugin-mailchimp';

import Layout from '../../components/Layout';

import imgBlockR from '../../assets/images/webinar/Photo 1 (1).png';

import imgDots from '../../assets/images/home-page/Dots.png';

import mondayConfig from '../../constants/monday-config';

import '../../assets/styles/pages/webinar-page.scss';

const EMPTY_FORM_DATA = {
  firstName: '',
  lastName: '',
  userEmail: '',
};

const Webinar = () => {
  const [formData, setFormData] = useState(EMPTY_FORM_DATA);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const mondayData = {
      BOARD_ID: mondayConfig.boardLeadManagementId,
      GROUP_ID: mondayConfig.groupId,
      itemName: `${formData.firstName} ${formData.lastName}`,
      values: {
        status_3: { label: 'Webinar' }, // Source
        status_5: mondayConfig.status,
        first_name: formData.firstName,
        last_name: formData.lastName,
        email9: formData.userEmail,
        date5: mondayConfig.date,
        status_1: window?.userFromGoogleAds ? 'Yes' : 'No', // Google Ads
        status_101: window?.userFromFacebookAds ? 'Yes' : 'No', // Facebook Ads
      },
    };

    await fetch('/api/monday', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(mondayData),
    });

    const MailchimpData = {
      FNAME: formData.firstName,
      LNAME: formData.lastName,
      MMERGE4: new Date().toISOString().split('T')[0], // contact date
      MMERGE17: 'Webinar', // Source
    };
    addToMailchimp(formData.userEmail, MailchimpData);

    setFormData(EMPTY_FORM_DATA);
    navigate('/webinar/thank-you');
  };
  return (
    <Layout
      title="TEFL & Coronavirus Webinar - Everything You Need To Know"
      description="Join TEFL Iberia for a discussion on how the Coronavirus has impacted the TEFL industry and what we anticipate for TEFL in 2022."
    >
      <div className="webinar-page">
        <section className="second-section">
          <div className="container">
            <div className="row top">
              <div className="col-12 col-lg-6 col-md-5">
                <div className="block_r">
                  <div className="block_r__img">
                    <img src={imgBlockR} alt="" className="block_r__img" />
                  </div>

                  <div className="block__logo block_r__trinity">
                    <img src={imgDots} alt="iconTrinity" />
                  </div>
                </div>
              </div>

              <div className="col-12 col-lg-6 col-md-7">
                <div className="block_l">
                  <h1 className="c-title-42">
                    Register Now: TEFL & Coronavirus - Everything You Need To Know
                  </h1>
                  <p className="c-text-18">
                    Are you thinking about teaching English abroad but not sure how things will work
                    with Coronavirus? Richard and Jayde discuss how the virus has impacted the
                    industry, where you can go and how you can get started.
                  </p>
                  <div className="form-hat">
                    <h4 className="form-hat__title">Thursday 17th December, 8pm CET</h4>
                    <p className="form-hat__string">Spain — 8pm</p>
                    <p className="form-hat__string">United Kingdom — 7pm</p>
                    <p className="form-hat__string">New York — 2pm</p>
                    <p className="form-hat__string">Austin — 1pm</p>
                    <p className="form-hat__string">California — 11am</p>
                  </div>
                  <form onSubmit={handleSubmit} className="form">
                    <h1 className="c-title-22 form__title">Register for free webinar</h1>

                    <label className="form__first-name">
                      <p className="c-text-14 text-under">First Name</p>
                      <input
                        className="input__first-name"
                        type="text"
                        placeholder="John"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleInputChange}
                        required
                      />
                    </label>
                    <label className="form__last-name">
                      <p className="c-text-14 text-under">Last Name</p>
                      <input
                        className="input__last-name"
                        type="text"
                        placeholder="Smith"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleInputChange}
                        required
                      />
                    </label>

                    <label className="form__first-name email">
                      <p className="c-text-14 text-under">Email</p>
                      <input
                        className="input__email"
                        type="email"
                        placeholder="johnsmith@gmail.com"
                        name="userEmail"
                        value={formData.userEmail}
                        onChange={handleInputChange}
                        required
                      />
                    </label>
                    <button type="submit" className="form__btn c-btn c-btn--red">
                      Register
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default Webinar;
